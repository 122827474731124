.home-page {
  .banner-block {
    position: relative;
    .tips-block {
      position: absolute;
      z-index: 9999999;
      left: rpx(10);
      right: rpx(10);
      bottom: 0;
      height: rpx(30);
      line-height: rpx(30);
      border-radius: 0 0 rpx(8) rpx(8);
      background-color: rgba($color: #000000, $alpha: 0.5);
      color: white;
    }
  }
  .m-swiper {
    height: rpx(200);
    margin-left: rpx(10);
    margin-right: rpx(10);
    margin-top: rpx(10);
    width: rpx(355);
    border-radius: rpx(8);
    .pic-size {
      width: rpx(355);
      height: rpx(200);
      border-radius: rpx(8);
    }
    .dot-block {
      bottom: rpx(30);
      right: rpx(20);
      left: unset;
      width: unset;
    }
    .swiper-pagination-bullet {
      background-color: white;
      opacity: 1;
    }
    .dot-active {
      width: rpx(16);
      border-radius: rpx(4);
      opacity: 1;
      transition: width;
      background-color: $primary-color;
    }
  }
}
