.detail-page {
  .head-img {
    width: rpx(355);
  }
  .prop-line {
    .label {
      width: rpx(80);
      color: $text-color-3;
      font-size: rpx(15);
    }
    .value {
      height: rpx(34);
      line-height: rpx(34);
      font-size: rpx(14);
      &.pic {
        height: rpx(39);
      }
    }
    .value input[disabled] {
      padding-left: rpx(10);
      width: calc(100% - 10px);
      background: #f5f5f5 !important;
    }
    .place-holder {
      color: $text-color-9;
    }
    .input {
      border: none;
      width: 100%;
      height: 100%;
      background-color: unset;
      padding: 0;
      font-size: rpx(14);
      &:focus-visible {
        outline: none;
      }
      &::placeholder {
        color: $text-color-9;
      }
      &:-moz-placeholder {
        color: $text-color-9;
      }
      &::-webkit-input-placeholder {
        color: $text-color-9;
      }
    }
    .upload-pic {
      width: rpx(180);
      height: rpx(100);
      position: relative;
      border: 1px solid $border-color-t;
      .icon-close-block {
        position: absolute;
        z-index: 10;
        right: 0;
        top: 0;
        width: rpx(10);
        height: rpx(10);
        background-color: rgba($color: #000000, $alpha: 0.5);
        line-height: 0;
        border-radius: 0 rpx(4);
      }
      .upload-input {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .example-block {
      width: rpx(100);
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 10px;
      margin-left: 20px;
    }
    .example-img {
      width: 100%;
      height: 100%;
    }
  }
  .goods-detail {
    img {
      display: block;
      width: 100%;
    }
  }
  .footer-btn {
    position: fixed;
    left: 0;
    bottom: 0;
    border-top: 1px solid rgb(248, 248, 251);
  }
  .submit-btn {
    width: 100%;
    height: rpx(46);
    line-height: rpx(46);
    border-radius: rpx(46);
    text-align: center;
    color: white;
    background: linear-gradient(90deg, $primary-color-t 0%, $primary-color 100%);
  }
  .verify-btn {
    width: rpx(60);
    border-radius: rpx(35);
    text-align: center;
    color: white;
    background: linear-gradient(90deg, $primary-color-t 0%, $primary-color 100%);
  }
  .disable-verify-btn {
    background: #999;
  }
  .video-box {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .video-img {
      position: absolute;
      width: rpx(80);
      border-radius: 50%;
      background: #fff;
    }
    .opacity-none {
      opacity: 0;
    }
  }
}
.emptybox {
  .tips {
    margin-top: rpx(10);
    font-weight: 400;
  }
}
.bg-shallow {
  background: rgba(0, 0, 0, 0.5);
}
