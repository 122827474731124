.select-phone-dialog {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999999999;
  .mask {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    z-index: 0;
  }
  .content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50vh;
    background-color: white;
    border-radius: rpx(20) rpx(20) 0 0;
    display: flex;
    flex-direction: column;
    .title-block {
      display: flex;
      flex-direction: row;
      height: rpx(44);
      line-height: rpx(44);
      padding-left: rpx(44);

      .title {
        flex: 1;
        text-align: center;
        color: #333;
      }

      .close {
        width: rpx(40);
        height: rpx(40);
        text-align: center;

        .close-icon {
          width: rpx(20);
          height: rpx(20);
        }
      }
    }
    .card-content {
      flex: 1;
      overflow-y: auto;
      padding: rpx(10);
      display: flex;
      flex-wrap: wrap;
      &::-webkit-scrollbar {
        display: none;
      }
      .card-item {
        width: rpx(170);
        margin-right: rpx(10);
        height: rpx(40);
        line-height: rpx(40);
        border: 1px solid #dedede;
        border-radius: rpx(10);
        text-align: center;
        margin-bottom: rpx(10);
        position: relative;
        &:nth-child(even) {
          margin-right: 0;
        }
        &.active {
          border-color: $primary-color;
          color: $primary-color;
          background-color: rgba($color: $primary-color-t, $alpha: 0.1);
          .icon-select {
            display: inherit;
          }
        }
        .icon-select {
          position: absolute;
          right: -1px;
          top: -1px;
          width: rpx(24);
          height: rpx(24);
          display: none;
        }
      }
    }
    .card-btn-block {
      display: flex;
      flex-direction: row;
      padding: rpx(10);
      .card-btn {
        width: rpx(170);
        height: rpx(46);
        border-radius: rpx(46);
        border: 1px solid $primary-color;
      }
      .switch-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: rpx(10);
        color: $primary-color;
        .refresh-icon {
          width: rpx(16);
          height: rpx(16);
          margin-right: rpx(5);
        }
      }
      .card-confirm-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $primary-color;
        color: white;
        .card-select {
          font-size: rpx(12);
        }
      }
    }
  }
  .loading-png {
    width: rpx(200);
    height: rpx(170);
  }
}
