.certificates-page {
  overflow-y: auto;
  background-color: #fff;
  .upload-box {
    height: rpx(200);
    position: relative;
    .upload-image {
      width: rpx(200);
      .icon-close-block {
        position: absolute;
        right: rpx(10);
        top: rpx(10);
        width: rpx(25);
        height: rpx(25);
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        .icon-close-img {
          width: rpx(20);
          height: rpx(20);
        }
      }
    }
    .upload-input {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
    }
    .upload-img {
      width: rpx(110);
      height: rpx(95);
    }
    .hand-photo {
      width: rpx(100);
      height: rpx(110);
    }
  }
}
