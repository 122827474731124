.refund-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 5vh 5vw;
  z-index: 999999;
  color: $text-color-3;

  .refund-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #00000067;
  }

  .refund-center {
    position: relative;
    background-color: #fff;
    border-radius: rpx(10);
    height: 36vh;
    display: flex;
    flex-direction: column;
    z-index: 999;

    .refund-title-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: rpx(44);
      padding-left: rpx(40);

      .refund-title {
        flex: 1;
        text-align: center;
        font-size: rpx(14);
      }

      .refund-close {
        width: rpx(40);
        height: rpx(40);
        padding: rpx(10);
        box-sizing: border-box;
        .close-icon {
          width: rpx(20);
          height: rpx(20);
        }
      }
    }

    .refund-content {
      overflow: auto;
      flex: 1;

      .refund-content-line {
        padding: rpx(5);
        font-size: $font-size-12;
      }
      .input {
        height: rpx(55);
        outline: none;
        border: 1px solid #e6e6e6;
      }
      .btn {
        border-radius: rpx(40);
      }
    }

    .upload-pic {
      width: rpx(80);
      height: rpx(70);
      position: relative;
      border: 1px solid $border-color-t;
      .icon-close-block {
        position: absolute;
        z-index: 10;
        right: 0;
        top: 0;
        width: rpx(10);
        height: rpx(10);
        background-color: rgba($color: #000000, $alpha: 0.5);
        line-height: 0;
        border-radius: 0 rpx(4);
      }
      .upload-input {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
