.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 999;
  .pic {
    animation: rotoImg 2s linear infinite;
    width: rpx(60);
    height: rpx(60);
  }
  .tips {
    font-size: rpx(18);
  }
}
@keyframes rotoImg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
