@use 'sass:math';
$dWidth: 375;
@function rpx($d) {
  @return math.div($d, $dWidth) * 100 * 1vw;
}
$bg-white: white;
$bg-gray: #f8f8fb;
$bg-ccc: #cccccc;
$border-color: #f5f5f5;
$border-color-t: #e6e6e6;
$primary-color: #7766e7;
$primary-color-t: #998aff;
$primary-color-blue: #3a9cff;
$text-color-9: #999999;
$text-color-6: #666666;
$text-color-3: #333333;
$text-color-red: #ff5733;
$text-color-red-t: rgba($text-color-red, 0.1);

// 字号
$font-size-12: rpx(12);
$font-size-14: rpx(14);
$font-size-16: rpx(16);
$font-size-18: rpx(18);
$font-size-20: rpx(20);
$font-size-22: rpx(22);
$font-size-24: rpx(24);
$font-size-26: rpx(26);
$fss: 10, 12, 14, 16, 18, 20, 22, 24, 26, 30;
// 底部导航栏高度
$bottom-nav-bar-height: rpx(44);

@each $fs in $fss {
  .font-size-#{$fs} {
    font-size: rpx($fs);
  }
  .line-height-#{$fs} {
    line-height: rpx($fs);
  }
  .text-indent-#{$fs} {
    text-indent: rpx($fs);
  }
}
$wss: 0, 10, 12, 14, 16, 18, 20, 22, 24, 26, 30, 40, 80, 100, 120;

@each $ws in $wss {
  .w-#{$ws} {
    width: rpx($ws);
  }
  .h-#{$ws} {
    height: rpx($ws);
  }
}

// * {
//   -webkit-user-select: none; /* Safari */
//   -moz-user-select: none; /* Firefox */
//   -ms-user-select: none; /* IE10+/Edge */
//   user-select: none; /* Standard syntax */
// }

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: rpx(14);
  color: $text-color-3;
  overflow: hidden;
}

.bw-100 {
  width: 100%;
}
.bh-100 {
  height: 100%;
}

.w-1 {
  width: 1px;
}
.h-1 {
  height: 1px;
}

.bg-gray {
  background-color: $bg-gray;
}
.bg-ccc {
  background-color: $bg-ccc;
}
.bg-555 {
  background-color: $border-color;
}
.bg-blue {
  background-color: $primary-color-blue;
}
.bg-white {
  background-color: $bg-white;
}
.bg-p {
  background-color: $primary-color;
}
.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid $border-color;
}
.border-ccc {
  border: 1px solid $bg-ccc;
}
.border-left {
  border-left: 1px solid $border-color;
}
.border-right {
  border-right: 1px solid $border-color;
}
.border-top {
  border-top: 1px solid $border-color;
}
.border-bottom {
  border-bottom: 1px solid $border-color;
}

$space: 0, 3, 4, 5, 8, 10, 14, 15, 20, 25, 30, 40, 50, 60, 70;
@each $sp in $space {
  .m-#{$sp} {
    margin: rpx($sp);
    box-sizing: border-box;
  }
  .ml-#{$sp} {
    margin-left: rpx($sp);
    box-sizing: border-box;
  }
  .mr-#{$sp} {
    margin-right: rpx($sp);
    box-sizing: border-box;
  }
  .mt-#{$sp} {
    margin-top: rpx($sp);
    box-sizing: border-box;
  }
  .mb-#{$sp} {
    margin-bottom: rpx($sp);
    box-sizing: border-box;
  }
  .p-#{$sp} {
    padding: rpx($sp);
    box-sizing: border-box;
  }
  .pl-#{$sp} {
    padding-left: rpx($sp);
    box-sizing: border-box;
  }
  .pr-#{$sp} {
    padding-right: rpx($sp);
    box-sizing: border-box;
  }
  .pt-#{$sp} {
    padding-top: rpx($sp);
    box-sizing: border-box;
  }
  .pb-#{$sp} {
    padding-bottom: rpx($sp);
    box-sizing: border-box;
  }
}

$brs: 4, 5, 8, 10, 40;

@each $br in $brs {
  .border-radius-#{$br} {
    border-radius: rpx($br);
  }
}

.text-color-p {
  color: $primary-color;
}
.text-color-blue {
  color: $primary-color-blue;
}
.text-color-9 {
  color: $text-color-9;
}
.text-color-6 {
  color: $text-color-6;
}
.text-color-3 {
  color: $text-color-3;
}
.text-color-white {
  color: white;
}
.text-color-red {
  color: $text-color-red;
}

.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-flex-r {
  display: flex;
  flex-direction: row;
}

.d-flex-c {
  display: flex;
  flex-direction: column;
}

$flxs: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;

@each $fl in $flxs {
  .flex-#{$fl} {
    flex: $fl;
  }
}

$icons: 12, 14, 16, 18, 20, 22, 24, 26, 40;
@each $ic in $icons {
  .icon-#{$ic} {
    width: rpx($ic);
    height: rpx($ic);
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}
.ai-inherit {
  align-items: inherit;
}
.ai-start {
  align-items: flex-start;
}

.ai-center {
  align-items: center;
}

.ai-end {
  align-items: flex-end;
}

.ac-start {
  align-content: flex-start;
}
.ac-center {
  align-content: center;
}
.ac-end {
  align-content: flex-end;
}

.jc-start {
  justify-content: flex-start;
}

.jc-center {
  justify-content: center;
}

.jc-end {
  justify-content: flex-end;
}

.jc-between {
  justify-content: space-between;
}

.jc-around {
  justify-content: space-around;
}

.jc-evenly {
  justify-content: space-evenly;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.opacity-none {
  opacity: 0;
}
.opacity-block {
  opacity: 1;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}
.overflow-x {
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}

.omit-text {
  max-lines: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.word-wrap {
  word-wrap: break-word;
  white-space: break-spaces;
}
.word-nowrap {
  white-space: nowrap;
}
.cursor-pointer {
  cursor: pointer;
}
.font-widget-600 {
  font-weight: 600;
}

.bottom-box {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.4);
  .content {
    border-radius: rpx(10) rpx(10) 0 0;
    background-color: white;
  }
}

.box-shadow {
  box-shadow: 0 rpx(6) rpx(10) rgba($color: $primary-color-t, $alpha: 0.08);
}

.input-confirm-box {
  border: 1px solid $border-color;
  border-radius: rpx(4);
  .input {
    border: none;
    height: rpx(30);
    &:focus-visible {
      outline: none;
    }
  }
}

.confirm-box {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.4);
  .content {
    border-radius: rpx(5);
    background-color: white;
    padding-top: 0;
    width: rpx(250);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text {
      text-align: center;
      word-wrap: break-word;
      margin-bottom: rpx(20);
      margin-left: rpx(20);
      margin-right: rpx(20);
      box-sizing: border-box;
      color: $text-color-9;
    }
  }
}

.toast-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-color: transparent;
  .toast {
    position: fixed;
    bottom: 50vh;
    box-sizing: border-box;
    padding: rpx(10);
    margin: rpx(10);
    font-size: rpx(14);
    background-color: rgba($color: #000000, $alpha: 0.8);
    border-radius: rpx(10);
    border: 1px solid #777;
    min-width: rpx(150);
    text-align: center;
    color: white;
    z-index: 9999999999;
  }
}

.error-block {
  margin-top: rpx(50);
  text-align: center;
  .err-img {
    width: 60vw;
  }
}

.app-loading {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $bg-gray;
  color: $text-color-9;
}

// 复制组件
.copy-to-clipboard {
  .text-area {
    width: 0;
    height: 0;
    position: fixed;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
}
