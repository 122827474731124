.empty {
  .pic {
    width: rpx(200);
    height: rpx(200);
  }
  .tips {
    font-size: rpx(24);
    font-weight: 600;
  }
  .back-btn {
    width: rpx(170);
    height: rpx(46);
    border-radius: rpx(46);
    line-height: rpx(46);
    text-align: center;
    color: white;
    background: linear-gradient(90deg, $primary-color-t 0%, $primary-color 100%);
  }
}
