.message-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  color: $text-color-3;
  font-size: rpx(16);

  .message-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #00000067;
  }

  .message-center {
    position: relative;
    background-color: #fff;
    border-radius: rpx(10);
    width: 80%;
    min-height: 20vh;
    max-height: 50vh;
    display: flex;
    flex-direction: column;
    z-index: 999;
    padding-bottom: rpx(20);

    .message-title-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: rpx(44);
      padding: rpx(10) rpx(10) 0 rpx(40);
      justify-content: flex-end;

      .message-title {
        flex: 1;
        color: red;
        font-size: rpx(18);
        font-weight: bold;
        text-align: center;
      }

      .message-close {
        width: rpx(30);
        height: rpx(30);
        box-sizing: border-box;
        .close-icon {
          width: 100%;
          height: 100%;
        }
      }
    }

    .message-content {
      overflow: auto;
      flex: 1;
      padding: 0 rpx(10);
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: rpx(30);

      .message-content-line {
        padding: rpx(5);
        font-size: $font-size-12;
      }
      .message-btn {
        width: rpx(200);
        text-align: center;
        margin-top: rpx(20);
        padding: rpx(10) 0;
        border-radius: rpx(10);
        background: $primary-color;
        color: #fff;
      }
    }
  }
}
