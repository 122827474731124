.app-bar {
  width: 100vw;
  height: rpx(44);
  background-color: $bg-white;
  position: relative;
  .app-bar-title {
    position: relative;
    line-height: rpx(44);
    text-align: center;
  }
  .app-bar-back {
    position: absolute;
    z-index: 2;
    left: rpx(10);
    top: rpx(12);
    width: rpx(20);
    height: rpx(20);
  }
  .app-bar-action {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: rpx(10);
  }
}
