.order-list-page {
  .search-block {
    height: rpx(40);
    border-radius: rpx(40);
    padding: 0 rpx(10);
    .search-input {
      width: 100%;
      border: none;
      height: rpx(40);
      line-height: rpx(40);

      &:focus-visible {
        outline: none;
      }
    }
    .search-btn {
      height: rpx(34);
      line-height: rpx(34);
      border-radius: rpx(34);
      width: rpx(60);
      background: linear-gradient(90deg, $primary-color-t 0%, $primary-color 100%);
    }
  }
  .prop-line {
    display: flex;
    flex-direction: row;
    margin-bottom: rpx(10);
    .label {
      width: rpx(80);
      color: $text-color-9;
    }
  }
  .divider {
    margin-left: rpx(80);
    margin-top: rpx(10);
    margin-bottom: rpx(10);
    border-bottom: 1px solid $border-color;
  }
  .transition {
    transition: all 0.5s;
  }
}
