.protocol-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 5vh 5vw;
  z-index: 999999;
  color: $text-color-3;

  .protocol-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #00000067;
  }

  .protocol-center {
    position: relative;
    background-color: #fff;
    border-radius: rpx(10);
    height: 90vh;
    display: flex;
    flex-direction: column;
    z-index: 999;

    .protocol-title-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: rpx(44);
      padding-left: rpx(40);

      .protocol-title {
        flex: 1;
        text-align: center;
        font-size: rpx(14);
      }

      .protocol-close {
        width: rpx(40);
        height: rpx(40);
        padding: rpx(10);
        box-sizing: border-box;
        .close-icon {
          width: rpx(20);
          height: rpx(20);
        }
      }
    }

    .protocol-content {
      overflow: auto;
      flex: 1;

      .protocol-content-line {
        padding: rpx(5);
        font-size: $font-size-12;
      }
    }
  }
}
