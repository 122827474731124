.picture-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 20vh 5vw;
  z-index: 999999;
  color: $text-color-3;

  .picture-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #00000067;
  }

  .picture-center {
    position: relative;
    background-color: #fff;
    border-radius: rpx(10);
    display: flex;
    flex-direction: column;
    z-index: 999;

    .picture-title-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: rpx(44);
      padding-left: rpx(40);

      .picture-title {
        flex: 1;
        text-align: center;
        font-size: rpx(14);
      }

      .picture-close {
        width: rpx(40);
        height: rpx(40);
        padding: rpx(10);
        box-sizing: border-box;
        .close-icon {
          width: rpx(20);
          height: rpx(20);
        }
      }
    }

    .picture-content {
      overflow: auto;
      flex: 1;
      padding: rpx(10);
      img {
        width: 100%;
      }

      .picture-content-line {
        padding: rpx(5);
        font-size: $font-size-12;
      }
    }
  }
}
