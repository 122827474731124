.goods-item {
  width: rpx(172);
  margin-right: rpx(10);
  overflow: hidden;
  &:nth-child(2n) {
    margin-right: 0;
  }
  .goods-img {
    width: rpx(172);
    height: rpx(172);
  }
}
